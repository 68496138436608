import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { fetchMerchantOrders, merchantSettings, submitOrderPayment } from "../../api";
import {
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Badge,
  Button,
  ButtonGroup,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  InputLeftAddon,
  Input,
  InputGroup,
  Select,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import moment from "moment";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OrderInfoPopup } from "./OrderInfoPopup";
import { OrderStatus } from "./OrderStatus";

const _defValues = { pwd: "", method: "usdt", transaction: "", uuid: "" };

export const MerchantOrdersPage = () => {
  const toast = useToast();
  const [ordersDate, setOrdersDate] = useState<Date>(new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orderInfoUuid, setOrderInfoUuid] = useState("");
  const [modalUuid, setModalUuid] = useState("");
  const { isLoading, isError, data, error, refetch } = useQuery<any[], { message: string }>(
    ["merchant-orders", { ordersDate }],
    fetchMerchantOrders
  );
  const { mutate, isLoading: isSaving } = useMutation(submitOrderPayment(), {
    onSuccess: onClose,
    onError: (e) => {
      toast({
        title: `Submit payment error`,
        position: "top",
        status: "error",
      });
    },
  });

  const formik = useFormik({
    initialValues: { ..._defValues },
    // @ts-ignore
    onSubmit: mutate,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const handleMarkAsPaid = (uuid: string) => {
    setModalUuid(uuid);
    formik.setFieldValue("uuid", uuid);
    onOpen();
  };

  return (
    <>
      <DatePicker
        dateFormat="dd-MM-yyyy"
        selected={ordersDate}
        onChange={(date: Date) => setOrdersDate(date)}
      />
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>uuid</Th>
            <Th>external id</Th>
            <Th isNumeric>amount,$</Th>
            <Th textAlign="center">status</Th>
            <Th textAlign="center">request date</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.length &&
            data.map((order, index) => (
              <Tr key={order.id}>
                <Td>{order.uuid}</Td>
                <Td>{order.internalOrderId}</Td>
                <Td isNumeric>{order.amount}</Td>
                <Td textAlign="center">
                  <OrderStatus isPaid={order.isPaid} paymentExpiration={order.paymentExpiration} />
                </Td>
                <Td isNumeric>{moment(order.createdAt).format("MMMM Do YYYY, HH:mm:ss")}</Td>
                <Td>
                  <ButtonGroup spacing="3">
                    <IconButton
                      aria-label="Info"
                      variant="ghost"
                      colorScheme="blue"
                      size="xs"
                      icon={<AiOutlineInfoCircle size="20" />}
                      onClick={() => {
                        setOrderInfoUuid(order.uuid);
                      }}
                    />
                    <Button
                      colorScheme="teal"
                      variant="outline"
                      size="xs"
                      onClick={() => {
                        handleMarkAsPaid(order.uuid);
                      }}
                      disabled={order.isPaid}
                    >
                      Mark as paid
                    </Button>
                  </ButtonGroup>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader>{modalUuid}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Select name="method" onChange={formik.handleChange} value={formik.values["method"]}>
                <option value="usdt">USDT</option>
                <option value="crypto">Crypto</option>
              </Select>
              <InputGroup style={{ padding: "10px 0" }}>
                <InputLeftAddon children="password" width="120px" />
                <Input
                  type="password"
                  name="pwd"
                  onChange={formik.handleChange}
                  value={formik.values["pwd"]}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon children="hash" width="120px" />
                <Input
                  name="transaction"
                  onChange={formik.handleChange}
                  value={formik.values["transaction"]}
                />
              </InputGroup>
              <input type="hidden" name="uuid" value={formik.values["uuid"]} />
            </ModalBody>
            <ModalFooter>
              <Button type="submit" colorScheme="teal" isLoading={isSaving}>
                Submit Payment
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {orderInfoUuid && (
        <OrderInfoPopup
          uuid={orderInfoUuid}
          unsetUuid={() => {
            setOrderInfoUuid("");
          }}
        />
      )}
    </>
  );
};
