import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  Button,
  IconButton,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { fetchWalletTransactions } from "../api";
import { AiOutlineCopy, AiOutlineLeft } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";

export const TransactionsPage = () => {
  const { coin, hash } = useParams<{ coin: string; hash: string }>();

  const { isLoading, isError, data, error } = useQuery<
    { addr: string; transactions: any[] },
    { message: string },
    { addr: string; transactions: any[]; balance: string; trxBalance: string; transactionDetailsUrl: string },
    [_key: string, params: { hash: string }]
    // @ts-ignore
  >(["transactions", { coin, hash }], fetchWalletTransactions);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  if (!data) {
    return <span>Error no data</span>;
  }

  return (
    <>
      <Link to="/app/wallets" style={{ width: "200px" }}>
        <Button
          colorScheme={"blue"}
          leftIcon={<AiOutlineLeft />}
          justifyContent="flex-start"
          variant={"ghost"}
        >
          Wallets
        </Button>
      </Link>
      <StatGroup style={{ width: "300px" }}>
        <Stat>
          <StatLabel>USDT</StatLabel>
          <StatNumber>{data.balance}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>TRX</StatLabel>
          <StatNumber>{data.trxBalance}</StatNumber>
        </Stat>
      </StatGroup>
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th textAlign="center">id</Th>
            <Th textAlign="center">from</Th>
            <Th textAlign="center">to</Th>
            <Th textAlign="center">value</Th>
            <Th textAlign="center">Coin</Th>
            <Th textAlign="center">date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.transactions &&
            data.transactions.length > 0 &&
            data.transactions.map((transaction) => {
              const { hash, value, from, to, tms } = transaction;
              const isIncome = data.addr === to;
              return (
                <Tr key={hash}>
                  <Td isNumeric>
                    <Button
                      as="a"
                      href={`${data.transactionDetailsUrl}${hash}`}
                      target="_blank"
                      colorScheme="blue"
                      variant="link"
                    >
                      {hash.slice(0, 5)}...{hash.slice(-5)}
                    </Button>
                  </Td>
                  <Td isNumeric>
                    {from.slice(0, 5)}...{from.slice(-5)}
                    <IconButton aria-label="" variant="link" icon={<AiOutlineCopy />} colorScheme="gray" />
                  </Td>
                  <Td isNumeric>
                    {to.slice(0, 5)}...{to.slice(-5)}
                    <IconButton aria-label="" variant="link" icon={<AiOutlineCopy />} colorScheme="gray" />
                  </Td>
                  <Td isNumeric color={isIncome ? "green.500" : "red.500"}>
                    {isIncome ? "+" : "-"}
                    {value}
                    {/*{(parseInt(value) / 1000000).toFixed(4)}*/}
                  </Td>
                  <Td>USDT</Td>
                  <Td isNumeric>{new Date(tms).toDateString()}</Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </>
  );
};
