import styled, { createGlobalStyle } from "styled-components";
import { YMInitializer } from "react-yandex-metrika";
import {FC} from "react";

interface OrderLayoutProps {
  metricsId?: number;
}

export const OrderLayout: FC<OrderLayoutProps> = ({ children, metricsId }) => (
  <>
    <GlobalStyle />
    {metricsId && (
      <YMInitializer
        accounts={[metricsId]}
        options={{
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        }}
      />
    )}
    <Layout>{children}</Layout>
  </>
);

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "MontserratBold";
    src: url("/assets/Montserrat-Bold.ttf");
  }
  @font-face {
    font-family: "Montserrat";
    src: url("/assets/Montserrat-Medium.ttf");
  }
  .ReactCollapse--collapse {
    transition: height 300ms;
  }
`;

const Layout = styled.div`
  font-family: Montserrat;
  letter-spacing: 0em;
`;
