import {
  Center,
  Checkbox,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import { fetchUserWallets, createWallet, submitOrderPayment, submitUserTransactions } from "../../api";
import { Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";
import { AiOutlineUnorderedList, AiOutlinePlus } from "react-icons/ai";
import { BsBoxArrowInDown, BsBoxArrowUp } from "react-icons/bs";
import { Link } from "react-router-dom";
import React, { useState, ReactElement } from "react";
import { useFormik } from "formik";

type ModalWindowType = {
  id: 1 | 2;
  title: string;
  icon: ReactElement;
  asset: string;
};
const Modals: ModalWindowType[] = [
  {
    id: 1,
    title: "Multi withdrawal",
    icon: <BsBoxArrowUp />,
    asset: "USDT",
  },
  {
    id: 2,
    title: "Multi deposit",
    icon: <BsBoxArrowInDown />,
    asset: "TRX",
  },
];

export const Fiat: React.FC = () => {
  const toast = useToast();
  const [modal, setModal] = useState<ModalWindowType>(Modals[0]);
  const [selectedWallets, setSelectedWallets] = useState<string[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, isError, data, error } = useQuery<any[], { message: string }>(
    "wallets",
    fetchUserWallets
  );

  const { mutate: mutateUserTransactions, isLoading: isSaving } = useMutation(submitUserTransactions(), {
    onSuccess: onClose,
    onError: (e) => {
      toast({
        title: `User transactions error`,
        position: "top",
        status: "error",
      });
    },
  });

  const handleModal = (id: number) => () => {
    setModal(Modals.find((item) => item.id === id) ?? Modals[0]);
    onOpen();
  };

  const { mutate, isLoading: isCreating } = useMutation(createWallet(), {
    onError: (e) => {
      toast({
        title: `Create wallet error`,
        position: "top",
        status: "error",
      });
    },
  });

  const formik = useFormik({
    initialValues: { pwd: "", wallet: "", amount: 0 },
    // @ts-ignore
    onSubmit: (values) => {
      // console.log({ modal, selectedWallets, values });
      mutateUserTransactions({ type: modal.id, list: selectedWallets, ...values });
    },
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <>
      <Flex>
        <Button
          m={3}
          leftIcon={<AiOutlinePlus />}
          variant="outline"
          colorScheme="green"
          isLoading={isCreating}
          onClick={() => {
            if (window.confirm("Create USDT wallet?")) mutate();
          }}
        >
          Create
        </Button>
        <Spacer />
        {Modals.map(({ id, title, icon, asset }) => (
          <Button
            key={id}
            m={3}
            leftIcon={icon}
            variant="outline"
            colorScheme="blue"
            disabled={selectedWallets.length === 0}
            onClick={handleModal(id)}
          >
            {`${asset} ${title}`}
          </Button>
        ))}
      </Flex>
      <Table variant="simple" colorScheme="gray">
        <Thead>
          <Tr>
            <Th textAlign="center">Addr</Th>
            {/*<Th>#</Th>*/}
            {/*<Th textAlign="center">id</Th>*/}
            <Th textAlign="center">Coin</Th>
            <Th isNumeric>balance</Th>
            <Th isNumeric>priority</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.length &&
            data.map((wallet, index) => (
              <Tr key={wallet.id} background="" style={{}}>
                <Td>
                  <Checkbox
                    size="sm"
                    onChange={() => {
                      if (selectedWallets.indexOf(wallet.addr) === -1)
                        setSelectedWallets([...selectedWallets, wallet.addr]);
                      else setSelectedWallets(selectedWallets.filter((addr) => addr !== wallet.addr));
                    }}
                    isChecked={selectedWallets.indexOf(wallet.addr) > -1}
                  >
                    {wallet.addr}
                  </Checkbox>
                  {/*<Checkbox>{wallet.addr.slice(0, 7)}...{wallet.addr.slice(-7)}</Checkbox>*/}
                </Td>
                {/*<Td><Checkbox>{index + 1}</Checkbox></Td>*/}
                {/*<Td textAlign="center">{wallet.id}</Td>*/}
                <Td textAlign="center">{wallet.network.title}</Td>
                <Td isNumeric>{wallet.balance}</Td>
                <Td isNumeric>{wallet.priority}</Td>
                <Td isNumeric>
                  <Link to={`/app/wallets/${wallet.hash}`}>
                    <IconButton aria-label="" variant="link" icon={<AiOutlineUnorderedList />} />
                  </Link>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Modal onClose={onClose} isOpen={isOpen} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader>{`${modal.asset} ${modal.title}`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <InputGroup>
                  <InputLeftAddon children={`${modal.id === 1 ? "Destination" : "Source"} wallet`} />
                  {modal.id === 2 && (
                    <Select name="wallet" onChange={formik.handleChange} value={formik.values["wallet"]}>
                      <option key="" value="" />
                      {data &&
                        data.length &&
                        data
                          .filter(({ addr }) => selectedWallets.indexOf(addr) === -1)
                          .map(({ addr, hash }) => (
                            <option key={hash} value={addr}>
                              {addr}
                            </option>
                          ))}
                    </Select>
                  )}
                  {modal.id === 1 && (
                    <Input name="wallet" onChange={formik.handleChange} value={formik.values["wallet"]} />
                  )}
                  {/*<InputRightAddon children={`Balance: ${3000.12} TRX`} width="170px" />*/}
                </InputGroup>
                <InputGroup width="50%">
                  <InputLeftAddon children="Amount per 1 wallet" />
                  <Input
                    type="number"
                    step="0.01"
                    name="amount"
                    onChange={formik.handleChange}
                    // value={formik.values["amount"]}
                  />
                  <InputRightElement color="gray.600" children={modal.asset} />
                  {/*<InputRightAddon children="TRX" width="120px" />*/}
                </InputGroup>
                <InputGroup width="50%">
                  <InputLeftAddon children="password" />
                  <Input
                    type="password"
                    name="pwd"
                    onChange={formik.handleChange}
                    // value={formik.values["pwd"]}
                  />
                </InputGroup>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme="teal"
                isLoading={isSaving}
              >
                Send {modal.asset}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
