import { ReactComponent as UsdtIcon } from "./assets/usdt-icon.svg";
import React, { ReactNode } from "react";

export type OrderType = {
  disabledPayments: string[];
  info: {
    addr: string;
    internalOrderId: string;
    amount: number;
    paybisAmount: number;
    paymentExpiration: Date;
    isPaid: boolean;
  };
  paymentGate: "jtl" | "xpay";
  wallet: {
    addr: string;
  };
  merchant: {
    title: string;
    key: string;
  };
  quotes: { symbol: string; price: string }[];
  videos: { paymentId: string; youtubeId: string }[];
};

export type WalletReceiverType = {
  coin?: string;
  addr?: string;
  amount?: string;
};

export type PaymentMethodType = {
  id: string;
  content: ReactNode;
  getLink: (amount: string, addr: string | undefined) => string;
};

export type CoinType = {
  key: string;
  icon: ReactNode;
  title: string;
  addrCaption?: string;
};

export type HeaderPropsType = {
  methods: PaymentMethodType[];
  activeMethod: string;
  setActiveMethod: (id: string) => void;
};

export type PaymentDetailsPropsType = {
  isLoading: boolean;
  error?: string;
  order?: OrderType;
  isLoadingWallet: boolean;
  wallet: WalletReceiverType;
  activeMethod: string;
  isMobile: boolean;
  activeCoin: string;
  coins: CoinType[];
  setActiveCoin: (coin: string) => void;
};

export type ProviderScriptsType = {
  initialScript: string;
  fillAmount: string;
  fillUserEmail: string;
  fillWallet: string;
};

export enum PaymentGate {
  xpay = "xpay",
  jtl = "jtl",
}
