import { FC } from "react";
import styled from "styled-components";
import { CoinType } from "../types";

interface CryptoCoinsProps {
  list: CoinType[];
  activeCoin: string;
  onCoinChange: (coin: string) => void;
}

export const CryptoCoins: FC<CryptoCoinsProps> = (props) => {
  const { list, activeCoin, onCoinChange } = props;

  return (
    <List>
      {list.map((coin) => (
        <CoinItem key={coin.key} isActive={coin.key === activeCoin} onClick={() => onCoinChange(coin.key)}>
          {coin.icon}
          <p>{coin.title}</p>
        </CoinItem>
      ))}
    </List>
  );
};

const List = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const CoinItem = styled.div<{ isActive: boolean }>`
  display: flex;
  gap: 16px;
  align-items: center;
  background: ${({ isActive }) => (isActive ? "rgba(145, 158, 171, 0.24)" : "rgba(145, 158, 171, 0.08)")};
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  min-width: 150px;
`;
