import { FC } from "react";
import { PaymentMethodType } from "../types";

import styled, { css } from "styled-components";

interface PaymentMethodsProps {
  methods: PaymentMethodType[];
  activeMethod: string;
  setActiveMethod: (id: string) => void;
}

export const PaymentMethods: FC<PaymentMethodsProps> = (props) => {
  const { methods, activeMethod, setActiveMethod } = props;

  return (
    <Methods>
      {methods.map((method) => (
        <PaymentMethod
          key={method.id}
          isActive={method.id === activeMethod}
          onClick={() => setActiveMethod(method.id)}
        >
          {method.content}
        </PaymentMethod>
      ))}
    </Methods>
  );
};

const Methods = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
`;

const PaymentMethod = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 24px 12px;
  border-radius: 8px;
  box-shadow: rgba(145, 158, 171, 0.2) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  cursor: pointer;
  font-weight: 700;
  ${({ isActive }) => css`
    background: ${isActive ? "rgba(145, 158, 171, 0.16)" : "#fff"};
  `}
`;
