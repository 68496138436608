import { Button, FormControl, Input, InputGroup, InputLeftAddon, VStack, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import axios from "axios";
import { useState } from "react";

type FormFieldsNameType = { current: string; new: string; repeat: string };

const formFeilds: { name: keyof FormFieldsNameType; title: string; type: string }[] = [
  { name: "current", title: "current password", type: "password" },
  { name: "new", title: "new password", type: "password" },
  { name: "repeat", title: "repeat password", type: "password" },
];

export const Security = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik<FormFieldsNameType>({
    initialValues: { current: "", new: "", repeat: "" },
    onSubmit: (values) => {
      setIsLoading(true);

      axios({
        method: "POST",
        url: "/auth/update-password",
        data: values,
      })
        .then(() => {
          toast({
            status: "success",
            title: `Success password update`,
            position: "top-right",
            isClosable: true,
          })
        })
        .catch((e) => {
          toast({
            status: "error",
            title: `Reject password update`,
            position: "top-right",
            isClosable: true,
          })
          console.error(e)
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack spacing={4} style={{ alignItems: "flex-start" }}>
        {formFeilds.map(({ title, name, type }) => (
          <FormControl key={name} style={{ maxWidth: "500px" }}>
            <InputGroup>
              <InputLeftAddon children={title.toUpperCase()} width="200px" />
              <Input name={name} type={type} onChange={formik.handleChange} value={formik.values[name]} />
            </InputGroup>
          </FormControl>
        ))}
        <Button type="submit" colorScheme="green" isLoading={isLoading}>
          Update
        </Button>
      </VStack>
    </form>
  );
};
