import { Table, Tbody, Th, Thead, Tr, Td, Icon, IconButton } from "@chakra-ui/react";
import React from "react";
import { useQuery } from "react-query";
import {
  AiOutlineFieldTime,
  AiOutlineStop,
  AiOutlineCheck,
  AiOutlineSearch,
  AiOutlineSync,
} from "react-icons/ai";
import { fetchUserTransactions } from "../../api";
import moment from "moment";

const parseTxId = (msg: string) => {
  try {
    return JSON.parse(msg).txid;
  } catch (e) {
    return null;
  }
};

export const History: React.FC = () => {
  const { isLoading, isError, data, error, refetch } = useQuery<any[], { message: string }>(
    "user-transactions",
    fetchUserTransactions
  );

  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <>
      <IconButton
        isLoading={isLoading}
        aria-label="Refresh"
        onClick={() => refetch()}
        icon={<AiOutlineSync />}
        variant="outline"
        colorScheme="green"
      />
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>from</Th>
            <Th>to</Th>
            <Th>asset</Th>
            <Th isNumeric>amount</Th>
            <Th textAlign="center">status</Th>
            <Th textAlign="center">date</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.length &&
            data.map((item) => (
              <Tr key={item.id}>
                <Td fontSize="sm">
                  {/*{item.addrFrom}*/}
                  {item.addrFrom.slice(0, 7)}...{item.addrFrom.slice(-7)}
                </Td>
                <Td fontSize="sm">
                  {/*{item.addrTo}*/}
                  {item.addrTo.slice(0, 7)}...{item.addrTo.slice(-7)}
                </Td>
                <Td>{item.asset?.title}</Td>
                <Td isNumeric>{item.amount}</Td>
                <Td textAlign="center">
                  {item.status === 0 && <Icon as={AiOutlineFieldTime} w={6} h={6} color="gray.500" />}
                  {item.status === 1 && <Icon as={AiOutlineCheck} w={6} h={6} color="green.500" />}
                  {item.status === 2 && <Icon as={AiOutlineStop} w={6} h={6} color="red.500" />}
                  {item.status === 3 && <Icon as={AiOutlineStop} w={6} h={6} color="orange.500" />}
                </Td>
                <Td fontSize="sm">{moment(item.createdAt).format("MMMM Do YYYY, HH:mm:ss")}</Td>
                <Td textAlign="center">
                  {item.status === 1 && (
                    <IconButton
                      as="a"
                      href={`https://tronscan.io/#/transaction/${parseTxId(item.message)}`}
                      target="_blank"
                      variant="ghost"
                      colorScheme="blue"
                      aria-label="Blockchain info"
                      icon={<AiOutlineSearch />}
                    />
                  )}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  );
};
