import {
  Button,
  Box,
  HStack,
  Image,
  StackDivider,
  VStack,
  ButtonProps,
  Container,
  Badge,
  Icon,
  Heading,
  Text,
} from "@chakra-ui/react";
import {
  AiFillHome,
  AiFillSetting,
  AiOutlineWallet,
  AiOutlineInfoCircle,
  AiOutlineShoppingCart
} from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import logo from "../img.png";
import styled from "styled-components";

const appMenu = [
  { title: "Account", path: "/app/account", icon: <AiFillHome /> },
  // { title: "Transactions", path: "/app/transactions", icon: <AiOutlineCloudServer /> },
  { title: "Crypto wallets", path: "/app/wallets", icon: <AiOutlineWallet /> },
  { title: "Orders", path: "/app/orders", icon: <AiOutlineShoppingCart /> },
  { title: "Settings", path: "/app/settings", icon: <AiFillSetting /> },
];

const MenuItemBtn = ({ to, isActive, children, ...rest }: { to: string; children: any } & ButtonProps) => (
  <Link to={to} style={{ width: "100%" }}>
    <Button
      {...rest}
      colorScheme={isActive ? "blue" : "gray"}
      isFullWidth
      justifyContent="flex-start"
      isDisabled={isActive}
      variant={isActive ? "outline" : "ghost"}
    >
      {children}
    </Button>
  </Link>
);

export const AppLayout = ({ children }: { children: any }) => {
  const { pathname } = useLocation();

  return (
    <HStack
      minH="600px"
      spacing="20px"
      divider={<StackDivider borderColor="gray.300" />}
      style={{ padding: 20 }}
      alignItems="flex-start"
    >
      <Box w="200px">
        <VStack spacing="15px" align="flex-start" h="100%">
          <Image src={logo} boxSize="180px" objectFit="cover" margin="20px auto" />
          {appMenu.map(({ path, icon, title }) => (
            <MenuItemBtn key={path} to={path} leftIcon={icon} isActive={pathname.indexOf(path) === 0}>
              {title}
            </MenuItemBtn>
          ))}
        </VStack>
      </Box>
      <Box w="100%">
        <NoticeContainer>
          <HStack>
            <Badge style={{ padding: "20px 20px 15px 20px", borderRadius: "10px" }}>
              <Icon as={AiOutlineInfoCircle} w={7} h={7} color="purple.500" />
            </Badge>
            <VStack spacing={1} alignItems="flex-start">
              <Heading as="h6" size="xs">
                How to use
              </Heading>
              <Text fontSize="xs">Go to github to see examples</Text>
            </VStack>
          </HStack>
        </NoticeContainer>
        {children}
      </Box>
    </HStack>
  );
};

const NoticeContainer = styled(Container)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border: rgba(0,0,0,0.1) 1px solid;
  border-radius: 10px;
  margin: 0 0 20px 0;
`;
