import {
  FormControl,
  FormHelperText,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  Spinner,
  Text,
  VStack,
  Skeleton,
} from "@chakra-ui/react";
import { AiOutlineEdit, AiOutlineCheck } from "react-icons/ai";
import { useState } from "react";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { merchantSettings } from "../../api";

const formFeilds = [
  { name: "key", title: "id", type: "text" },
  { name: "title", title: "title", type: "text" },
  { name: "secret", title: "secret", type: "text" },
  { name: "ipnUrl", title: "Ipn url", type: "text" },
  {
    name: "gapCrypto",
    title: "% crypto",
    description: "% add to Binance price crypto to crypto",
    type: "number",
  },
  { name: "gapFiat", title: "$ fiat", description: "$ add to price Card To Crypto", type: "number" },
];

export const SettingsPage = () => {
  const [isEditable, setIsEditable] = useState(false);
  const { mutate, isLoading: isSaving } = useMutation(merchantSettings("update"));

  const formik = useFormik({
    initialValues: Object.fromEntries(formFeilds.map(({ name }) => [name, ""])),
    onSubmit: async (values) => {
      if (isEditable) await mutate(values);
      setIsEditable(!isEditable);
    },
  });

  const { isLoading, isError, data, error } = useQuery("settings", merchantSettings("fetch"), {
    onSuccess: (data) => {
      formik.setValues(data);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack spacing={4} style={{ marginTop: "50px", alignItems: "flex-start" }}>
        {isSaving ? (
          <Spinner size="lg" color="blue.500" />
        ) : (
          <IconButton
            type="submit"
            isLoading={isSaving}
            aria-label="Search database"
            variant="ghost"
            size="md"
            icon={
              isEditable ? (
                <AiOutlineCheck size="25" color="green" />
              ) : (
                <AiOutlineEdit size="25" color="blue" />
              )
            }
          />
        )}
        {formFeilds.map(({ title, name, description, type }) => (
          <FormControl key={name} style={{ maxWidth: "600px" }}>
            <Skeleton isLoaded={!isLoading}>
              <InputGroup>
                <InputLeftAddon children={title.toUpperCase()} width="120px" />
                {type === "text" && (
                  <Input
                    name={name}
                    isDisabled={!isEditable || name === "key"}
                    onChange={formik.handleChange}
                    value={formik.values[name]}
                  />
                )}
                {type === "number" && (
                  <NumberInput value={formik.values[name] ?? ""} min={0} max={50} isDisabled={!isEditable}>
                    <NumberInputField
                      name={name}
                      onChange={formik.handleChange}
                    />
                  </NumberInput>
                )}
              </InputGroup>
              {!!description && <FormHelperText>{description}</FormHelperText>}
            </Skeleton>
          </FormControl>
        ))}
      </VStack>
    </form>
  );
};
