import { FC } from "react";
import styled from "styled-components";
import { OrderLayout } from "../../../layout/order";
import { PaymentMethods } from "./PaymentMethods";
import { PaymentDetails } from "./PaymentDetails";
import { CoinType, OrderType, PaymentMethodType, WalletReceiverType } from "../types";
import { CryptoCoins } from "./CryptoWallets";
import { ReactComponent as ArrowIcon } from "../assets/arrow-icon.svg";

interface JTLOrderPageProps {
  methods: PaymentMethodType[];
  coins: CoinType[];
  isLoading: boolean;
  isLoadingWallet: boolean;
  errorMessage?: string;
  activeMethod: string;
  activeCoin: string;
  wallet: WalletReceiverType;
  order?: OrderType;
  youtubeId?: string;
  payBtnLink: string;
  onMethodChange: (method: string) => void;
  onCoinChange: (coin: string) => void;
}

export const JTLOrderPage: FC<JTLOrderPageProps> = (props) => {
  const {
    methods,
    coins,
    isLoadingWallet,
    isLoading,
    wallet,
    order,
    activeMethod,
    payBtnLink,
    activeCoin,
    youtubeId,
    errorMessage,
    onCoinChange,
    onMethodChange,
  } = props;

  return (
    <OrderLayout>
      <Container>
        <Wrapper>
          <PaymentMethods methods={methods} activeMethod={activeMethod} setActiveMethod={onMethodChange} />
          {activeMethod === "crypto" && (
            <CryptoCoins list={coins} activeCoin={activeCoin} onCoinChange={onCoinChange} />
          )}
          <PaymentDetails
            order={order}
            activeMethod={activeMethod}
            wallet={wallet}
            isLoading={isLoadingWallet}
          />
          {payBtnLink && activeMethod !== "crypto" && (
            <PaymentButton href={payBtnLink} target={"_blank"} rel={"noreferrer"}>
              <PaymentButtonText>
                Go to <PaymentButtonMethod>{activeMethod}</PaymentButtonMethod>
              </PaymentButtonText>
              <ArrowIcon />
            </PaymentButton>
          )}
        </Wrapper>
      </Container>
    </OrderLayout>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
  overflow-x: hidden;

  @media (min-width: 576px) {
    max-width: 540px;
    padding: 0 15px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 40px 0;
  color: rgba(33, 43, 54, 1);
`;

const PaymentButton = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 190px;
  margin: auto;
  padding: 12px 16px;
  background: #00a76f;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(34, 197, 94, 0.24);
  transition: all 0.3s;
  font-weight: 600;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 167, 111, 0.24);
    background: #007867;
  }
`;

const PaymentButtonText = styled.span`
  flex: 1;
  justify-self: center;
  text-align: center;
`;

const PaymentButtonMethod = styled.span`
  text-transform: capitalize;
`;
