import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import styled from "styled-components";
import { Security } from "./Security";
import { Info } from "./Info";

export const AccountPage = () => {
  return (
    <Tabs variant="enclosed" isFitted>
      <TabList>
        <Tab>Info</Tab>
        <Tab>Security</Tab>
      </TabList>

      <TabsPanelStyled>
        <TabPanel>
          <Info />
        </TabPanel>
        <TabPanel>
          <Security />
        </TabPanel>
      </TabsPanelStyled>
    </Tabs>
  );
};

const TabsPanelStyled = styled(TabPanels)`
  border: rgba(0, 0, 0, 0.1) 1px solid;
  border-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
