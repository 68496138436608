import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { HeaderPropsType, PaymentDetailsPropsType, PaymentMethodType } from "../types";
import { MobileHeader } from "./MobileHeader";
import { Collapse } from "react-collapse";
import { ReactComponent as CollapseUpIcon } from "../assets/collapse-up.svg";
import { ReactComponent as CollapseDownIcon } from "../assets/collapse-down.svg";
import { ReactComponent as WaitingIcon } from "../assets/waiting.svg";
import { ReactComponent as YoutubeIcon } from "../assets/youtube-icon.svg";
import { PaymentDetails } from "./PaymentDetails";
import { CryptoMethodDetails } from "./CryptoMethodDetails";
import { getPaymentProviderScripts } from "./providers";

const _logEvent = (logMsg: string) => {
  // @ts-ignore
  if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(logMsg);
  else console.log(logMsg);
};

export const MobileView: React.FC<HeaderPropsType & PaymentDetailsPropsType & { youtubeId?: string }> = (
  props
) => {
  const { order, activeMethod, activeCoin, coins, setActiveCoin, isMobile, youtubeId, methods, wallet } =
    props;

  const [isCollapsed, setIsCollapsed] = useState(true);
  useEffect(() => {
    _logEvent(
      "EVENT::ORDER_INIT::JSON_DATA::" +
        JSON.stringify({
          providers: methods.map((item: PaymentMethodType) => ({
            code: item.id.toUpperCase(),
            url: item.getLink(wallet.amount ?? "", "[[ addr ]]"),
            scripts: getPaymentProviderScripts(item.id),
          })),
        })
    );
  }, []);

  useEffect(() => {
    if (!wallet.coin || !wallet.addr) return;
    _logEvent("EVENT::ORDER_CHANGE_WALLET::JSON_DATA::" + JSON.stringify({ wallet }));
  }, [wallet]);

  return (
    <MainContainer>
      <HeaderLine>
        {youtubeId && (
          <Link href={`https://www.youtube.com/watch?v=${youtubeId}`} target="_blank">
            <YoutubeIcon />
            <Value style={{ fontSize: 12 }}>How to Pay?</Value>
          </Link>
        )}
      </HeaderLine>
      <Collapse isOpened={isCollapsed}>
        <ExpandedContainer>
          <Title>Card Payment Provider</Title>
          <MobileHeader {...props} />
          <Title>Payment Details</Title>
          <Details>
            <ItemContainer>
              <Label>Merchant</Label>
              <Value>{order?.merchant.title}</Value>
            </ItemContainer>
            <ItemContainer>
              <Label>Amount</Label>
              <Value>${order?.info?.amount}</Value>
            </ItemContainer>
            <ItemContainer>
              <Label>Status</Label>
              <Value style={{ color: "#F79E1B" }}>
                <WaitingIcon />
                {!order?.info?.isPaid ? "Waiting" : "Success"}
              </Value>
            </ItemContainer>
          </Details>
        </ExpandedContainer>
      </Collapse>
      <ExpandButton
        onClick={() => {
          const value = !isCollapsed;
          setIsCollapsed(value);
          _logEvent(`EVENT::PAYMENT_DETAILS::${!value ? "HIDE" : "SHOW"}`);
        }}
      >
        {isCollapsed ? <CollapseUpIcon /> : <CollapseDownIcon />}
      </ExpandButton>
      <FixedContainer>
        <PaymentDetails {...props} />
        {activeMethod === "crypto" && (
          <CryptoMethodDetails
            isMobile={isMobile}
            activeCoin={activeCoin}
            coinsList={coins}
            handleChange={async (coin) => {
              if (coin === activeCoin) return;
              setActiveCoin(coin);
            }}
          />
        )}
      </FixedContainer>
    </MainContainer>
  );
};

const HeaderLine = styled.div`
  width: 100%;
  height: 35px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const MainContainer = styled.div`
  margin: auto;
  min-width: 330px;
  max-width: 420px;
  line-height: 24px;
`;
const FixedContainer = styled.div`
  padding: 10px 12px;
`;
const ExpandedContainer = styled.div`
  padding: 10px 12px;
  background-color: #e4e4e4;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;
const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const Title = styled.div`
  font-family: MontserratBold;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
`;
const Label = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: #808080;
`;
const Value = styled.div`
  font-weight: 600;
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;
const ItemContainer = styled.div``;
const ExpandButton = styled.div`
  width: 100%;
  height: 25px;
  background-color: #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
