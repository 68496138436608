import styled, { css } from "styled-components";
import CardsIcon from "../assets/cards-logo.svg";
import BtcIcon from "../assets/btc-icon.svg";
import UsdtIcon from "../assets/usdt-icon.svg";
import EthIcon from "../assets/eth-icon.svg";
import { HeaderPropsType } from "../types";

export const Header: React.FC<HeaderPropsType> = ({ activeMethod, setActiveMethod, methods}) => (
  <Container>
    <HeaderItem></HeaderItem>
    <HeaderItem>
      <Methods>
        {methods.map(({ id, content }) => (
          <MethodButton key={id} isActive={activeMethod === id} onClick={() => setActiveMethod(id)}>
            {content}
            <IconsContainer>
              {id !== "crypto" && (
                <>
                  <CardsIconMC />
                  <CardsIconVisa />
                </>
              )}
              {id === "crypto" && (
                <>
                  {[BtcIcon, EthIcon, UsdtIcon].map((icon, index) => (
                    <CryptoIcon key={index} icon={icon}/>
                  ))}
                </>
              )}
            </IconsContainer>
          </MethodButton>
        ))}
      </Methods>
    </HeaderItem>
    <HeaderItem></HeaderItem>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
`;
const HeaderItem = styled.div``;
const Methods = styled.div`
  display: flex;
  min-width: 550px;
  justify-content: center;
  gap: 10px;
`;
const MethodButton = styled.div`
  width: 128px;
  height: 65px;
  border-radius: 8px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  ${(props: { isActive?: boolean }) => css`
    cursor: ${props.isActive ? "default" : "pointer"};
    background: ${props.isActive ? "#e5e5f0" : "#f5f5fa"};
    box-shadow: ${props.isActive
      ? "inset 1px 1px 2px rgba(170, 170, 204, 0.5);"
      : "1px 1px 2px rgba(170, 170, 204, 0.5), -1px -1px 2px #ffffff"};
  `}
`;
const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;
const CardsIconMC = styled.div`
  background-image: url(${CardsIcon});
  width: 40px;
  height: 20px;
`;
const CardsIconVisa = styled(CardsIconMC)`
  background-position-y: bottom;
  height: 15px;
  width: 40px;
`;
const CryptoIcon = styled.div`
  background-image: url(${(props: { icon: any }) => props.icon});
  background-size: contain;
  width: 25px;
  height: 25px;
`;
