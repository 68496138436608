import React from "react";
import moment from "moment/moment";
import { Badge } from "@chakra-ui/react";

export const OrderStatus: React.FC<{ isPaid: boolean; paymentExpiration: string }> = ({
  isPaid,
  paymentExpiration,
}) => {
  let status: string = "Waiting";
  if (isPaid) status = "Paid";
  if (!isPaid && moment(paymentExpiration).diff(moment()) < 0) status = "Expired";

  return (
    <Badge variant="solid" colorScheme={{ Waiting: "orange", Paid: "green", Expired: "red" }[status]}>
      {status}
    </Badge>
  );
};
