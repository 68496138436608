import { FC, ReactNode, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import moment from "moment";
import { OrderType, WalletReceiverType } from "../types";
import { _formatDiff } from "../utils/format-diff";
import styled, { css } from "styled-components";
import { ReactComponent as WaitingIcon } from "../assets/waiting.svg";
import { ReactComponent as CopyIcon } from "../assets/copy-icon.svg";
import { Skeleton } from "@chakra-ui/react";

interface PaymentDetailsProps {
  order?: OrderType;
  activeMethod: string;
  isLoading: boolean;
  wallet: WalletReceiverType;
}

export const PaymentDetails: FC<PaymentDetailsProps> = (props) => {
  const { order, activeMethod, wallet, isLoading } = props;

  const renderStatus = (
    <Item label={"Status"} isLoading={isLoading} color={!order?.info?.isPaid ? "" : ""}>
      {!order?.info?.isPaid ? (
        <>
          <WaitingIcon style={{ marginRight: 5 }} /> Waiting
        </>
      ) : (
        "Success"
      )}
    </Item>
  );

  return (
    <Wrapper>
      <Details>
        <Row>
          <Item label={"Amount"} isLoading={isLoading}>
            {wallet.amount} {wallet.coin?.toUpperCase()}
            <CopyIconBtn onClick={async () => await navigator.clipboard.writeText(wallet.amount || "")} />
          </Item>
          <EstimatedTime order={order} isLoading={isLoading} />
          {renderStatus}
        </Row>
        <Row>
          <Item
            isLoading={isLoading}
            fullWidth
            label={`${wallet.coin?.toUpperCase()} ${wallet.coin === "usdt" ? "TRC-20" : ""} wallet address`}
          >
            <WalletText>{wallet.addr}</WalletText>
            <CopyIconBtn onClick={async () => await navigator.clipboard.writeText(wallet.addr || "")} />
          </Item>
        </Row>
      </Details>
      {wallet.addr && (
        <StyledQRCode
          enabled={"true"}
          value={
            wallet.coin && ["btc", "eth"].indexOf(wallet.coin) > -1
              ? `${{ btc: "bitcoin", eth: "ethereum" }[wallet.coin]}:${wallet.addr}?amount=${wallet.amount}`
              : wallet.addr
          }
          size={135}
        />
      )}
    </Wrapper>
  );
};

interface EstimatedTimeProps {
  order?: OrderType;
  isLoading: boolean;
}

const EstimatedTime: FC<EstimatedTimeProps> = (props) => {
  const { order, isLoading } = props;
  const [estimatedTime, setEstimatedTime] = useState<string>("00:00");

  useEffect(() => {
    if (!order) return;
    const _update = () => {
      const diff = moment.duration(moment(order.info.paymentExpiration).diff(moment()));
      if (diff.seconds() < 0) {
        setEstimatedTime("expired");
        return;
      }

      setEstimatedTime(_formatDiff(diff));
      setTimeout(_update, 1000);
    };
    _update();
  }, [order]);

  return (
    <Item isLoading={isLoading} label={"Time"}>
      {estimatedTime}
    </Item>
  );
};

interface ItemProps {
  label: string;
  isLoading: boolean;
  fullWidth?: boolean;
  color?: string;
  children: ReactNode;
}

const Item: FC<ItemProps> = (props) => {
  const { label, isLoading, fullWidth, color, children } = props;
  return (
    <ItemContainer fullWidth={fullWidth}>
      <ItemLabel>{label}</ItemLabel>
      <ItemContent isLoaded={!isLoading}>{children}</ItemContent>
    </ItemContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Details = styled.div`\
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 40px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
`;

const ItemContainer = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: ${({ fullWidth }) => (fullWidth ? "1" : "none")};
`;

const ItemLabel = styled.p`
  font-weight: 600;
  color: rgba(145, 158, 171, 1);
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const ItemContent = styled(Skeleton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: rgba(145, 158, 171, 0.08);
  padding: 12px;
  border-radius: 8px;
  min-width: 69px;
`;

const WalletText = styled.span`
  font-size: 12px;
  font-weight: 600;

  @media (min-width: 576px) {
    font-size: 16px;
    font-weight: 400;
  }
`;

const CopyIconBtn = styled(CopyIcon)`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledQRCode = styled(QRCode)`
  background: #fff;
  border: 10px rgba(145, 158, 171, 0.08) solid;
  border-radius: 8px;
  padding: 10px;
  width: 175px;
  height: 175px;
  margin: 0 auto;
  align-self: flex-end;
  ${(props: { enabled: string }) =>
    css`
      opacity: ${props.enabled === "true" ? "1" : "0"};
    `}
`;
