import { FC, ReactElement, useEffect, useState } from "react";
import {
  CoinType,
  OrderType,
  PaymentGate,
  PaymentMethodType,
  VfxOrderPage,
  WalletReceiverType,
} from "./vfxAlert";
import { JTLOrderPage } from "./jtl";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { fetchOrder, revealOrderWallet } from "../../api";
import { ReactComponent as MoonpayIcon } from "./assets/moonpay-logo.svg";
import { ReactComponent as RampIcon } from "./assets/ramp-logo.svg";
import { ReactComponent as PaybisIcon } from "./assets/paybis-logo.svg";
import { ReactComponent as BtcIcon } from "./assets/btc-icon.svg";
import { ReactComponent as UsdtIcon } from "./assets/usdt-icon.svg";
import { ReactComponent as EthIcon } from "./assets/eth-icon.svg";

const paymentMethods: PaymentMethodType[] = [
  {
    id: "moonpay",
    content: <MoonpayIcon />,
    getLink: (amount, addr) => "https://buy.moonpay.com/",
  },
  {
    id: "ramp",
    content: <RampIcon />,
    getLink: (amount, addr) => `https://ramp.network/buy`,
    // getLink: (amount, addr) =>
    //   `https://buy.ramp.network/?swapAsset=BTC&swapAmount=${Math.round(
    //     parseFloat(amount) * 10e7
    //   )}&userAddress=${addr}`,
  },
  {
    id: "paybis",
    content: <PaybisIcon />,
    getLink: (amount, addr) => `https://paybis.com/buy-tether-trc20/?amount=${amount}&currency=USD`,
  },
  {
    id: "crypto",
    content: "Cryptocurrency",
    getLink: (amount, addr) => "https://xpay.ninja/payment-instructions/crypto-mobile",
  },
];

const coins: CoinType[] = [
  { key: "btc", title: "Bitcoin", icon: <BtcIcon /> },
  { key: "eth", title: "Ethereum", icon: <EthIcon /> },
  { key: "usdt", title: "Tether", addrCaption: "USDT TRC-20", icon: <UsdtIcon /> },
  // { key: "ltc", title: "Litecoin", icon: <LtcIcon /> },
  // { key: "bch", title: "Bitcoin cash", icon: <BchIcon /> },
];

const defaultCoin = "usdt";

export const OrderPage: FC = () => {
  const [activeMethod, setActiveMethod] = useState("crypto");
  const [paymentMethodsFiltered, setPaymentMethodsFiltered] = useState(paymentMethods);
  const [activeCoin, setActiveCoin] = useState(defaultCoin);
  const [wallet, setWallet] = useState<WalletReceiverType>({ coin: activeCoin, addr: "", amount: "" });
  const [data, setData] = useState<OrderType>();

  const { orderId } = useParams<{ orderId: string }>();

  const { isLoading, isError, error } = useQuery<
    OrderType,
    { message: string },
    OrderType,
    [_key: string, params: { uuid: string }]
    // @ts-ignore
  >(["order-info", { uuid: orderId }], fetchOrder, { onSuccess: setData });

  const { mutate, isLoading: isLoadingWallet } = useMutation(
    revealOrderWallet<{ merchant?: string; order?: string; coin: string }, WalletReceiverType>(),
    {
      onSuccess: setWallet,
      onError: () => {
        setWallet({ coin: "", addr: "error", amount: "error" });
      },
    }
  );

  useEffect(() => {
    if (activeCoin !== defaultCoin)
      mutate({ order: orderId, coin: activeCoin, merchant: data?.merchant?.key });
    else setWallet({ coin: activeCoin, addr: data?.wallet.addr, amount: data?.info.amount.toString() });
  }, [activeCoin]);

  useEffect(() => {
    if (activeCoin !== defaultCoin) return;
    setWallet({ coin: defaultCoin, addr: data?.wallet.addr, amount: data?.info.amount.toString() });

    setPaymentMethodsFiltered(paymentMethods.filter(({ id }) => data?.disabledPayments.indexOf(id) === -1));
  }, [data]);

  useEffect(() => {
    // @ts-ignore
    setActiveMethod(paymentMethodsFiltered[0]?.["id"]);
  }, [paymentMethodsFiltered]);

  useEffect(() => {
    if (["ramp", "crypto"].indexOf(activeMethod) > -1) setActiveCoin("btc");
    else setActiveCoin(defaultCoin);
  }, [activeMethod]);

  const activeMethodObj: PaymentMethodType | undefined = paymentMethodsFiltered.find(
    ({ id }) => id === activeMethod
  );

  // @ts-ignore
  const payBtnLink: string = activeMethodObj?.getLink(
    (activeMethod === "paybis" ? data?.info?.paybisAmount.toFixed(2) : wallet.amount) ?? "",
    wallet.addr
  );

  const youtubeId: string | undefined = data?.videos?.find(
    ({ paymentId }) => paymentId === activeMethod
  )?.youtubeId;

  if (isLoading) return null;

  const props = {
    methods: paymentMethodsFiltered,
    coins,
    order: data,
    activeMethod,
    wallet,
    activeCoin,
    isLoading,
    isLoadingWallet,
    payBtnLink,
    youtubeId,
    onMethodChange: setActiveMethod,
    onCoinChange: setActiveCoin,
  };

  const views: Record<PaymentGate, ReactElement> = {
    xpay: <VfxOrderPage {...props} />,
    jtl: <JTLOrderPage {...props} />,
  };

  return views[data?.paymentGate ?? "xpay"];
};
