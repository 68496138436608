import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import styled from "styled-components";
import { Fiat } from "./Fiat";
import { Crypto } from "./Crypto";
import { History } from "./History";

export const WalletsPage = () => {
  return (
    <Tabs variant="enclosed" isFitted>
      <TabList>
        <Tab>Card to USDT</Tab>
        <Tab>Crypto</Tab>
        <Tab>History</Tab>
      </TabList>

      <TabsPanelStyled>
        <TabPanel>
          <Fiat />
        </TabPanel>
        <TabPanel>
          <Crypto />
        </TabPanel>
        <TabPanel>
          <History />
        </TabPanel>
      </TabsPanelStyled>
    </Tabs>
  );
};

const TabsPanelStyled = styled(TabPanels)`
  border: rgba(0, 0, 0, 0.1) 1px solid;
  border-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
