import styled, { css } from "styled-components";
import React from "react";
import { CoinType } from "../types";

export const CryptoMethodDetails: React.FC<{
  handleChange: (coin: string) => void;
  activeCoin: string;
  coinsList: CoinType[];
  isMobile?: boolean;
}> = (props) => {
  const { activeCoin, coinsList, handleChange, isMobile } = props;

  return (
    <Container>
      <Title isMobile={isMobile}>Select a payment method</Title>
      <ButtonsContainer size={coinsList.length} isMobile={isMobile}>
        {coinsList.map(({ key, title, icon }) => (
          <CoinButton
            isMobile={isMobile}
            isActive={activeCoin === key}
            key={key}
            onClick={() => handleChange(key)}
          >
            {icon}
            <span>{title}</span>
          </CoinButton>
        ))}
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 130px;
    justify-content: space-between;
`;
const Title = styled.div`
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;

  ${(props: { isMobile?: boolean }) =>
    css`
      ${props.isMobile ? "  font-family: MontserratBold; font-size: 16px; text-align: left;" : ""}
    `}
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

    ${(props: { size: number; isMobile?: boolean }) =>
    css`
      width: ${props.isMobile ? "100%" : (props.size * 150) + "px"};
    `}
`;
const CoinButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 7px;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  width: 140px;
  height: 50px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: #22283c;
    box-shadow: none;
  }

  & > span {
    width: 85px;
  }

  ${(props: { isActive: boolean; isMobile?: boolean }) => {
    let result: string = props.isActive
      ? `
          background: #e5e5f0;
          box-shadow: 1px 1px 2px rgba(170, 170, 204, 0.5);
          border-radius: 10px;
        `
      : `
          background: #f5f5fa;
          box-shadow: -6.7122px -6.7122px 13.4244px rgba(255, 255, 255, 0.5),
            6.7122px 6.7122px 13.4244px rgba(170, 170, 204, 0.25),
            13.4244px 13.4244px 26.8488px rgba(170, 170, 204, 0.5);
          border-radius: 13.4244px;
        `;

    result += `
      width: ${props.isMobile ? 100 : 140}px;
      height: ${props.isMobile ? 35 : 50}px;
      font-size: ${props.isMobile ? 11 : 16}px;
    `;
    return css`
      ${result}
    `;
  }}
`;
