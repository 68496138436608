import { ChakraProvider, theme } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { LoginPage } from "./page/Login";
import { AccountPage } from "./page/Account";
import { TransactionsPage } from "./page/Transactions";
import { SettingsPage } from "./page/Settings";
import { WalletsPage } from "./page/Wallets";
import { OrderPage } from "./page/Order";
import { MerchantOrdersPage } from "./page/MerchantOrders";
import React from "react";
import { AppLayout } from "./layout/app";
import useCookie from "react-use-cookie";
import { QueryClient, QueryClientProvider } from "react-query";

type PrivateRoutePropsType = { isAuthorized: boolean; redirectPath?: string; children?: any };
const PrivateRoute = ({ isAuthorized, redirectPath = "/landing", children }: PrivateRoutePropsType) => {
  if (!isAuthorized) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};

const queryClient = new QueryClient();

export const App = () => {
  const loginRoute: string = "/app/login";
  const [userToken, setUserToken] = useCookie("auth");
  const isAuthorized: boolean = !!userToken;

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path={"/order-pay/:orderId"} element={<OrderPage />}/>
            <Route path={loginRoute} element={isAuthorized ? <Navigate to="/app" /> : <LoginPage />} />
            <Route
              path="/app"
              element={
                <AppLayout>
                  <PrivateRoute redirectPath={loginRoute} isAuthorized={isAuthorized} />
                </AppLayout>
              }
            >
              <Route index element={<AccountPage />} />
              <Route path="account" element={<AccountPage />} />
              <Route path="wallets" element={<WalletsPage />} />
              <Route path="wallets/:hash" element={<TransactionsPage />} />
              <Route path="wallets/:coin/:hash" element={<TransactionsPage />} />
              <Route path="orders" element={<MerchantOrdersPage />} />
              <Route path="settings" element={<SettingsPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  );
};
