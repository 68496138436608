import { ProviderScriptsType } from "../types";

const scripts: Map<string, ProviderScriptsType> = new Map();

export const getPaymentProviderScripts = (provider: string): ProviderScriptsType | undefined =>
  scripts.get(provider);

scripts.set("moonpay", {
  initialScript: `
    (async () => {
      function delay(ms) {
        return new Promise(res => setTimeout(res, ms));
      }
      
      function log(message) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(message);
        } else {
          console.log(message);
        }
      }
      
      async function fillAmount(amount) {
        const amountInput = document.getElementById('quoteAmount');
        const submitButton = document.querySelector('[data-testid="submitButton"]');
        
        if (!amountInput) {
          log('amountInput not found');
          return;
        }
        
        log('inject fillAmount')
      
        function changeCurrency() {
          const button = amountInput.nextElementSibling;
          button.click();
      
          const tronButton = document.querySelector('[data-testid=currency-usdt_trx]');
          tronButton.click();
        }
      
        changeCurrency();
      
        const nativeInputSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
      
        nativeInputSetter.call(amountInput, amount);
        const event = new Event('input', { bubbles: true });
        amountInput.dispatchEvent(event);
      
        // await delay(1000);
      
        // submitButton.click();
      }
      
      function initialScript() {
        const mutationConfig = {
          attributes: true,
          childList: true,
          subtree: true,
          attributeOldValue: true,
          characterData: true,
          characterDataOldValue: true
        };
      
        const mutationCallback = () => {
          log('HTML::SNAPSHOT::' + document.getElementById('root').innerHTML.replace(/iframe/g, 'div'));
        }
        
        try {
          const observer = new MutationObserver(mutationCallback);
          observer.observe(document.getElementById('root'), mutationConfig);
        } catch (e) {
          log(JSON.stringify(e));
        }
      }
      
      await fillAmount(::amount::);
      initialScript();
    })()
  `,
  fillAmount: `
    (() => {
      function delay(ms) {
        return new Promise(res => setTimeout(res, ms));
      }
      
      function log(message) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(message);
        } else {
          console.log(message);
        }
      }
      
      async function fillAmount(amount) {
        const amountInput = document.getElementById('quoteAmount');
        const submitButton = document.querySelector('[data-testid="submitButton"]');
        
        if (!amountInput) {
          log('amountInput not found');
          return;
        }
        
        log('inject fillAmount')
      
        function changeCurrency() {
          const button = amountInput.nextElementSibling;
          button.click();
      
          const tronButton = document.querySelector('[data-testid=currency-usdt_trx]');
          tronButton.click();
        }
      
        changeCurrency();
      
        const nativeInputSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
      
        nativeInputSetter.call(amountInput, amount);
        const event = new Event('input', { bubbles: true });
        amountInput.dispatchEvent(event);
      
        // await delay(1000);
      
        // submitButton.click();
      }
      
      fillAmount(::amount::)
    })()
  `,
  fillUserEmail: `
    (() => {
      function log(message) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(message);
        } else {
          console.log(message);
        }
      }
      
      function fillUserEmail(email) {
        const emailInput = document.querySelector('input[type="email"]');
        if (!emailInput) {
          log('emailInput not found');
          return;
        }
        
        log('inject fillUserEmail')
      
        const nativeInputSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
        nativeInputSetter.call(emailInput, email);
      
        const event = new Event('input', { bubbles: true });
        emailInput.dispatchEvent(event);
      }
      
      fillUserEmail('::email::')
    })()
  `,
  fillWallet: `
    (() => {
      function log(message) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(message);
        } else {
          console.log(message);
        }
      }
      
      function fillWallet(wallet) {
        const walletInput = document.getElementById('walletAddress');
        if (!walletInput) {
          log('walletInput not found');
          return;
        }
        
        log('inject fillWallet')
      
        const nativeInputSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
        nativeInputSetter.call(walletInput, wallet);
      
        const event = new Event('input', { bubbles: true });
        walletInput.dispatchEvent(event);
      }
      
      fillWallet('::wallet::')
    })()
  `,
});

scripts.set("ramp", {
  initialScript: `
    (() => {
      function log(message) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(message);
        } else {
          console.log(message);
        }
      }
    
      function initialScript() {
        const mutationConfig = {
          attributes: true,
          childList: true,
          subtree: true,
          attributeOldValue: true,
          characterData: true,
          characterDataOldValue: true
        };
      
        const mutationCallback = (mutationList) => {
          if (document.getElementById('root')) {
            log('HTML::SNAPSHOT::' + document.getElementById('root').innerHTML.replace(/iframe/g, 'div'));
          }
          for (const mutation of mutationList) {
            if (mutation.type === 'childList') {
      
              if (mutation.addedNodes.length) {
                mutation.addedNodes.forEach((node) => {
                  if (node.nodeName === 'DIV' && node.previousElementSibling.nodeName === 'FOOTER') {
                    if (node.shadowRoot) {
                      const iframe = node.shadowRoot.querySelector('iframe');
                      log('IFRAME_URL::' + iframe.src);
                    }
                  }
                })
              }
            }
          }
        }
      
        const mutationObserver = new MutationObserver(mutationCallback);
        mutationObserver.observe(document.body, mutationConfig);
      
        const divButton = document.getElementById('ramp-widget-mobile');
        if (divButton) {
          divButton.querySelector('button').click();
        }
      }
      
      initialScript();
    })()
  `,
  fillAmount: `
    (() => {
      function log(message) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(message);
        } else {
          console.log(message);
        }
      }
      
      function delay(ms) {
        return new Promise((res) => setTimeout(res, ms));
      }
      
      async function fillAmount(amount) {
        async function changeCurrency() {
          let btcButton = document.querySelector('[data-test-id="asset-button-BTC_BTC"]');
          if (!btcButton) {
            const button = document.querySelector('[data-test-id="crypto-currency-button"]');
            button.click();
            await delay(0);
            btcButton = document.querySelector('[data-test-id="asset-button-BTC_BTC"]');
          }
      
          btcButton.click();
        }
      
        await changeCurrency();
      
        const amountInput = document.querySelector('[data-test-id=crypto-amount-input]');
      
        if (!amountInput) {
          log('amountInput not found');
          return;
        }
      
        log('inject fillAmount');
      
        await delay(0);
      
        const nativeInputSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
      
        nativeInputSetter.call(amountInput, amount);
        const event = new Event('input', { bubbles: true });
        amountInput.dispatchEvent(event);
      
        // const submitButton = document.querySelector('[data-test-id="proceed-button"]');
        // submitButton.click();
      }
      
      fillAmount('::amount::');
    })()
  `,
  fillWallet: `
    (() => {
      function log(message) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(message);
        } else {
          console.log(message);
        }
      }
    
      function fillWallet(wallet) {
        const input = document.querySelector('[data-test-id="crypto-address-input"]');
        if (!input) {
          log('walletInput not found');
          return;
        }
        
        log('inject fillWallet');
      
        const nativeInputSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
        nativeInputSetter.call(input, wallet);
        const event = new Event('input', { bubbles: true });
        input.dispatchEvent(event);
      }
      
      fillWallet('::wallet::')
    })()
  `,
  fillUserEmail: `
    (() => {
      function log(message) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(message);
        } else {
          console.log(message);
        }
      }
    
      function fillUserEmail(email) {
        const input = document.querySelector('[type="email"]');
        const termsCheckbox = document.querySelector('[data-test-id="tos-checkbox"]');
        if (!input || !termsCheckbox) {
          log('emailInput not found');
          return;
        }
        
        log('inject fillUserEmail');
      
        const nativeInputSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
      
        nativeInputSetter.call(input, email);
        const event = new Event('input', { bubbles: true });
        input.dispatchEvent(event);
        termsCheckbox.click();
      }
      
      fillUserEmail('::email::')
    })()
  `,
});
