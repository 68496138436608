import styled from "styled-components";

export const YoutubeInstruction: React.FC<{ id: string }> = ({ id }) => (
  <Container>
    <IFrame
      src={`https://www.youtube.com/embed/${id}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </Container>
);

const Container = styled.div`
  width: 100%;
  padding: 15px;
`;

const IFrame = styled.iframe`
  margin: auto;
  width: 850px;
  height: 480px;

  @media (max-width: 900px) {
    width: 425px;
    height: 240px;
  }
`;
