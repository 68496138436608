import { useEffect, useState } from "react";
import jwt from "jwt-decode";
import { Badge } from "@chakra-ui/react";

const getCookieByName = (name: string): string => {
  let result: string = "";

  document.cookie.split(";").forEach((item) => {
    const [key, value] = item.split("=");
    if (key.trim().toLowerCase() === name.toLowerCase()) result = value.trim();
  });

  return result;
};

export const Info = () => {
  const [email, setEmail] = useState<string>();

  useEffect(() => {
    const token: { email: string } = jwt(getCookieByName("auth"));
    if (token?.email) setEmail(token.email);
  }, []);

  return <Badge>{email}</Badge>;
};
