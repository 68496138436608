import axios, { AxiosRequestConfig } from "axios";
import { QueryFunction, QueryKey } from "react-query";

export const fetchUserWallets = async (): Promise<any[]> => {
  try {
    const { data }: { data: any[] } = await axios({ method: "GET", url: "/app-api/wallet/list" });
    return data;
  } catch (e) {
    throw new Error("Wallet request error");
  }
};

export const fetchWalletTransactions: QueryFunction<
  { addr: string; transactions: any[] },
  [_key: string, params: { hash: string; coin: string }]
> = async ({ queryKey }): Promise<{ addr: string; transactions: any[] }> => {
  const [_key, { hash, coin }] = queryKey;
  try {
    const { data }: { data: { addr: string; transactions: any[] } } = await axios({
      method: "GET",
      url: `/app-api/wallet/transactions/${hash}`,
    });
    return data;
  } catch (e) {
    throw new Error("Wallet transactions request error");
  }
};

// @ts-ignore
export const merchantSettings = (type: "update" | "fetch") => async (data) => {
  const config: AxiosRequestConfig = {
    url: "/app-api/merchant/settings",
  };
  config.method = type === "fetch" ? "GET" : "POST";
  if (type !== "fetch") config.data = data;

  try {
    const { data } = await axios(config);
    return data;
  } catch (e) {
    throw new Error("merchantSettings request error");
  }
};

// @ts-ignore
export const fetchOrder = async <FetchOrderResult>({ queryKey }): Promise<FetchOrderResult> => {
  const [_key, { uuid }] = queryKey;

  try {
    const { data }: { data: FetchOrderResult } = await axios({
      method: "GET",
      url: `/order-api/order/${uuid}`,
    });
    return data;
  } catch (e) {
    throw new Error("Order request error");
  }
};

// @ts-ignore
export const revealOrderWallet =
  <P, R>() =>
  async (params: P): Promise<R> => {
    try {
      const { data }: { data: R } = await axios({
        method: "post",
        url: `/order-api/reveal-wallet`,
        data: params,
      });
      return data;
    } catch (e) {
      throw new Error("Reveal wallet request error");
    }
  };

// @ts-ignore
export const cryptoWallets = (type: "update" | "fetch") => async (data) => {
  const config: AxiosRequestConfig = {
    url: "/app-api/wallet/crypto",
  };
  config.method = type === "fetch" ? "GET" : "POST";
  if (type !== "fetch") config.data = data;

  try {
    const { data } = await axios(config);
    return data;
  } catch (e) {
    throw new Error("cryptoWallets request error");
  }
};

// @ts-ignore
export const createWallet =
  <R>() =>
  async (): Promise<R> => {
    try {
      const { data }: { data: R } = await axios({
        method: "post",
        url: "/app-api/wallet/create",
      });
      return data;
    } catch (e) {
      throw new Error("Create Wallet request error");
    }
  };

// @ts-ignore
export const fetchMerchantOrders = async ({ queryKey }): Promise<any> => {
  const [_key, { ordersDate }] = queryKey;
  console.log(ordersDate);
  try {
    const { data } = await axios({
      method: "GET",
      url: "/app-api/order/list",
      params: {
        date: ordersDate.toISOString().substring(0, 10),
      },
    });
    return data;
  } catch (e) {
    throw new Error("Merchant orders-list request error");
  }
};

// @ts-ignore
export const fetchMerchantOrder = async <FetchOrderResult>({ queryKey }): Promise<FetchOrderResult> => {
  const [_key, { uuid }] = queryKey;

  try {
    const { data }: { data: FetchOrderResult } = await axios({
      method: "GET",
      url: `/app-api/order/details/${uuid}`,
    });
    return data;
  } catch (e) {
    throw new Error("Order request error");
  }
};

// @ts-ignore
export const submitOrderPayment =
  <P, R>() =>
  async (params: P): Promise<R> => {
    try {
      const { data }: { data: R } = await axios({
        method: "post",
        url: "/app-api/order/mark-as-paid",
        data: params,
      });
      return data;
    } catch (e) {
      throw new Error("Submit Order Payment request error");
    }
  };

type SubmitUserTransactionsParamsType = {
  type: 1 | 2;
  wallet: string;
  list: string[];
  pwd: string;
  amount: number;
};
export const submitUserTransactions =
  () =>
  async ({ type, ...params }: SubmitUserTransactionsParamsType) => {
    // console.log({ type, params });
    const availableTypes = { 1: "usdt-withdrawal", 2: "trx-deposit" };
    try {
      const { data } = await axios({
        method: "post",
        url: `/app-api/user-transactions/${availableTypes[type]}`,
        data: {
          pwd: params.pwd,
          amount: params.amount,
          [`destination${type === 2 ? "s" : ""}`]: type === 2 ? params.list : params.wallet,
          [`source${type === 1 ? "s" : ""}`]: type === 1 ? params.list : params.wallet,
        },
      });
      return data;
    } catch (e) {
      throw new Error("Submit User Transactions request error");
    }
  };

export const fetchUserTransactions = async (): Promise<any> => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/app-api/user-transactions/list",
    });
    return data;
  } catch (e) {
    throw new Error("User Transactions list request error");
  }
};
