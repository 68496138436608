import styled, { css } from "styled-components";
import CardsIcon from "./assets/cards-logo.svg";
import BtcIcon from "./assets/btc-icon.svg";
import UsdtIcon from "./assets/usdt-icon.svg";
import EthIcon from "./assets/eth-icon.svg";
import { HeaderPropsType } from "../types";
import { useEffect } from "react";

export const MobileHeader: React.FC<HeaderPropsType & { isLoading: boolean }> = ({
  activeMethod,
  setActiveMethod,
  methods,
  isLoading,
}) => {
  const onMethodChange = (id: string) => {
    setActiveMethod(id);
  };

  useEffect(() => {
    if (!isLoading) {
      const logMsg = `EVENT::SELECT_PAYMENT_PROVIDER::${activeMethod?.toUpperCase()}`;
      // @ts-ignore
      if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(logMsg);
      else console.log(logMsg);
    }
  }, [activeMethod]);

  return (
    <Container>
      <Methods>
        {methods.map(({ id, content }) => (
          <MethodButton key={id} isActive={activeMethod === id} onClick={() => setActiveMethod(id)}>
            <MethodButtonContent>{content}</MethodButtonContent>
          </MethodButton>
        ))}
      </Methods>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Methods = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const MethodButton = styled.div`
  width: 75px;
  border-radius: 8px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 800;
  line-height: 17px;
  align-items: center;
  justify-content: space-around;
  display: flex;
  ${(props: { isActive?: boolean; isMobile?: boolean }) => css`
    cursor: ${props.isActive ? "default" : "pointer"};
    background: ${props.isActive ? "#c7c7c7" : "#f5f5fa"};
    box-shadow: ${props.isActive
      ? "inset 1px 1px 2px rgba(170, 170, 204, 0.5);"
      : "1px 1px 2px rgba(170, 170, 204, 0.5), -1px -1px 2px #ffffff"};
  `}
`;
const MethodButtonContent = styled.div`
  transform: scale(0.6);
`;
