import { ChakraProvider, Text, VStack, Code, Grid, theme } from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { Logo } from "../Logo";
import { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useFormik } from "formik";
import axios from "axios";
import styled from "styled-components"

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

export const LoginPage = () => {
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: (values) => {
      setIsLoading(true);
      axios({
        method: "POST",
        url: "/auth/login",
        data: values,
      })
        .then(() => {
          window.location.href = "/app"
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.100"
      justifyContent="center"
      alignItems="center"
    >
      <Stack flexDir="column" mb="2" justifyContent="center" alignItems="center">
        {/*<Avatar bg="teal.500" />*/}
        {/*<Heading color="teal.400">Welcome</Heading>*/}
        <Box minW={{ base: "90%", md: "368px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={4} p="1rem" backgroundColor="whiteAlpha.900" boxShadow="md">
              <Logo h="150px" w="150px" style={{ margin: "auto" }} />
              <FormWrapper>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<CFaUserAlt color="gray.300" />} />
                  <Input
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    name="email"
                    type="email"
                    placeholder="email address"
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<CFaLock color="gray.300" />} />
                  <Input
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    name="password"
                    type="password"
                    placeholder="password"
                  />
                </InputGroup>
              </FormWrapper>
              {/*<FormControl>*/}
              {/*  <InputGroup>*/}
              {/*    <InputLeftElement*/}
              {/*      pointerEvents="none"*/}
              {/*      color="gray.300"*/}
              {/*      children={<CFaLock color="gray.300" />}*/}
              {/*    />*/}
              {/*    <Input type={showPassword ? "text" : "password"} placeholder="Password" />*/}
              {/*    <InputRightElement width="4.5rem">*/}
              {/*      <Button h="1.75rem" size="sm" onClick={handleShowClick}>*/}
              {/*        {showPassword ? "Hide" : "Show"}*/}
              {/*      </Button>*/}
              {/*    </InputRightElement>*/}
              {/*  </InputGroup>*/}
              {/*  <FormHelperText textAlign="right">*/}
              {/*    <Link>forgot password?</Link>*/}
              {/*  </FormHelperText>*/}
              {/*</FormControl>*/}
              <Button
                type="submit"
                variant="outline"
                colorScheme="cyan"
                size="md"
                // width="200px"
                border="1px"
                isLoading={isLoading}
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

const FormWrapper = styled(FormControl)`
  height: 90px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`