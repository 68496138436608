import styled, { css } from "styled-components";
import { Skeleton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { PaymentDetailsPropsType } from "../types";
import moment from "moment";
import { ReactComponent as TimerIcon } from "../assets/timer.svg";
import { ReactComponent as CopyIcon } from "../assets/copy-icon.svg";
import { _formatDiff } from "../utils/format-diff";

const EMPTY_TIME = "00:00";
const MAX_WIDTH = "900px";

const _logEvent = (logMsg: string) => {
  // @ts-ignore
  if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(logMsg);
  else console.log(logMsg);
};

export const PaymentDetails: React.FC<PaymentDetailsPropsType> = (props) => {
  const { isLoading, error, order, wallet, isLoadingWallet, activeMethod, isMobile } = props;

  const [estimatedTime, setEstimatedTime] = useState<string>(EMPTY_TIME);

  useEffect(() => {
    if (!order) return;
    const _update = () => {
      const diff = moment.duration(moment(order.info.paymentExpiration).diff(moment()));
      if (diff.seconds() < 0) {
        setEstimatedTime("expired");
        return;
      }

      setEstimatedTime(_formatDiff(diff));
      setTimeout(_update, 1000);
    };
    _update();
  }, [order]);

  if (error) return <ErrorContainer>{error}</ErrorContainer>;

  if (order?.info.isPaid) return <SucceedContainer>Succeed payment!</SucceedContainer>;

  if (estimatedTime === "expired") return <ErrorContainer>Expired!</ErrorContainer>;

  return (
    <Container
      style={{
        padding: isMobile ? 0 : "30px 0 65px",
        height: isMobile ? "auto" : "",
      }}
    >
      {!isMobile && (
        <ContentBlock>
          <ContentRow isLoaded={!isLoading}>
            <ItemContainer isFixed>
              <Label>Merchant</Label>
              <Value>{order?.merchant.title}</Value>
            </ItemContainer>
            <ItemContainer>
              <Label>Amount</Label>
              <Value>${order?.info?.amount}</Value>
            </ItemContainer>
          </ContentRow>
          <ContentRow isLoaded={!isLoading}>
            <ItemContainer isFixed>
              <Label>Order ID</Label>
              <Value>{order?.info?.internalOrderId}</Value>
            </ItemContainer>
            <ItemContainer>
              <Label>Status</Label>
              <Value>{!order?.info?.isPaid ? "Waiting" : "Success"}</Value>
            </ItemContainer>
          </ContentRow>
        </ContentBlock>
      )}
      <ContentBlock
        style={{
          justifyContent: isMobile ? "flex-start" : "space-between",
        }}
      >
        <ContentRow isLoaded={!isLoading}>
          <ItemContainerCustom>
            <Value isBoxed>
              {estimatedTime}
              <TimerIcon />
            </Value>
            <Skeleton isLoaded={!isLoadingWallet}>
              <Value isBoxed>
                <span>
                  {wallet.amount} {wallet.coin?.toUpperCase()}
                </span>
                <CopyIconBtn
                  onClick={async () => {
                    _logEvent(`EVENT::COPY_DATA::${wallet.amount}`);
                    return await navigator.clipboard.writeText(wallet.amount || "");
                  }}
                />
              </Value>
            </Skeleton>
          </ItemContainerCustom>
        </ContentRow>
        <ContentRow isLoaded={!isLoading}>
          <ItemContainer>
            <Skeleton isLoaded={!isLoadingWallet}>
              <Label>
                {wallet.coin?.toUpperCase()} {wallet.coin === "usdt" ? "TRC-20" : ""} wallet address
              </Label>
              <Value isBoxed style={{ minWidth: "270px", fontSize: isMobile ? 11 : 15 }}>
                <span>
                  {/*{wallet.addr?.slice(0, 9)}...{wallet.addr?.slice(-9)}*/}
                  {wallet.addr}
                </span>
                <CopyIconBtn
                  onClick={async () => {
                    _logEvent(`EVENT::COPY_DATA::${wallet.addr}`);
                    return await navigator.clipboard.writeText(wallet.addr || "");
                  }}
                />
              </Value>
            </Skeleton>
          </ItemContainer>
        </ContentRow>
      </ContentBlock>
      {wallet.addr && !isMobile && (
        <QRContainer
          enabled={(activeMethod === "crypto").toString()}
          value={
            wallet.coin && ["btc", "eth"].indexOf(wallet.coin) > -1
              ? `${{ btc: "bitcoin", eth: "ethereum" }[wallet.coin]}:${wallet.addr}?amount=${wallet.amount}`
              : wallet.addr
          }
          size={110}
        />
      )}
    </Container>
  );
};

const ErrorContainer = styled.div`
  text-align: center;
  padding: 50px 0;
  color: red;
`;
const SucceedContainer = styled(ErrorContainer)`
  color: green;
`;

const Container = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: rgba(128, 128, 128, 1);
  display: flex;
  height: 275px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${MAX_WIDTH}) {
    font-size: 15px;
    flex-direction: column;
    height: 530px;
    align-items: baseline;
  }
`;
const ContentRow = styled(Skeleton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
const ContentBlock = styled.div`
  height: 125px;
  display: flex;
  flex-direction: column;
`;
const Label = styled.div``;
const Value = styled.div`
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  ${(props: { isBoxed?: boolean }) => {
    return props.isBoxed
      ? css`
          background: #e4e4e4;
          border-radius: 8px;
          padding: 5px 10px;
          min-width: 100px;
        `
      : "";
  }}
`;
const ItemContainer = styled.div`
  ${(props: { isFixed?: boolean }) =>
    css`
      ${props.isFixed ? "width: 150px;" : ""}
    `}
`;
const ItemContainerCustom = styled(ItemContainer)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const QRContainer = styled(QRCode)`
  background: #fff;
  border: 10px #e4e4e4 solid;
  border-radius: 8px;
  padding: 10px;
  width: 150px;
  height: 150px;
  align-self: center;
  ${(props: { enabled: string }) =>
    css`
      opacity: ${props.enabled === "true" ? "1" : "0"};
    `}
`;

const CopyIconBtn = styled(CopyIcon)`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
