import React, { useState } from "react";
import {
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Spinner,
  VStack,
  Skeleton,
  InputRightAddon,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { cryptoWallets } from "../../api";
import { AiOutlineCheck, AiOutlineEdit, AiOutlineUnorderedList } from "react-icons/ai";
import { Link } from "react-router-dom";

const formFields = [
  { name: "btc", title: "Bitcoin", type: "text" },
  { name: "ltc", title: "Litecoin", type: "text" },
  { name: "eth", title: "Ethereum", type: "text" },
  { name: "bch", title: "Bitcoin cash", type: "text" },
];

export const Crypto: React.FC = () => {
  const [isEditable, setIsEditable] = useState(false);
  const { mutate, isLoading: isSaving } = useMutation(cryptoWallets("update"));

  const formik = useFormik({
    initialValues: Object.fromEntries(formFields.map(({ name }) => [name, ""])),
    onSubmit: async (values) => {
      if (isEditable) await mutate(values);
      setIsEditable(!isEditable);
    },
  });

  const { isLoading, isError, data, error } = useQuery<
    undefined,
    undefined,
    { wallets: { coin: string; addr: string }[] }
  >("wallets-crypto", cryptoWallets("fetch"), {
    onSuccess: (data) => {
      const formatted = Object.fromEntries(data.wallets.map(({ coin, addr }) => [coin.toLowerCase(), addr]));
      formik.setValues(formatted);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack spacing={4} style={{ marginTop: "50px", alignItems: "flex-start" }}>
        {isSaving ? (
          <Spinner size="lg" color="blue.500" />
        ) : (
          <IconButton
            type="submit"
            isLoading={isSaving}
            aria-label="Search database"
            variant="ghost"
            size="md"
            icon={
              isEditable ? (
                <AiOutlineCheck size="25" color="green" />
              ) : (
                <AiOutlineEdit size="25" color="blue" />
              )
            }
          />
        )}
        {formFields.map(({ title, name, type }) => (
          <FormControl key={name} style={{ maxWidth: "600px" }}>
            <Skeleton isLoaded={!isLoading}>
              <InputGroup>
                <InputLeftAddon children={title.toUpperCase()} width="140px" />
                <Input
                  name={name}
                  isDisabled={!isEditable || name === "key"}
                  onChange={formik.handleChange}
                  value={formik.values[name]}
                />
                <InputRightAddon
                  children={
                    <Link to={`/app/wallets/${name}/${formik.values[name]}`}>
                      <IconButton aria-label="" variant="link" icon={<AiOutlineUnorderedList />} />
                    </Link>
                  }
                />
              </InputGroup>
            </Skeleton>
          </FormControl>
        ))}
      </VStack>
    </form>
  );
};
