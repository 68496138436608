import { useQuery } from "react-query";
import { fetchMerchantOrder } from "../../api";
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { OrderStatus } from "./OrderStatus";

export const OrderInfoPopup = (props: { uuid: string; unsetUuid: () => void }) => {
  const { uuid, unsetUuid } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isLoading, isError, data, error, refetch } = useQuery<any, { message: string }>(
    ["merchant-order-details", { uuid }],
    fetchMerchantOrder
  );

  useEffect(() => {
    onOpen();
  }, [uuid]);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <Modal
      onClose={() => {
        unsetUuid();
        onClose();
      }}
      isOpen={isOpen}
      size="5xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{uuid} | {data?.internalOrderId}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading && <span>Loading...</span>}
          {!isLoading && !!data && (
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>addr</Th>
                  <Th textAlign="right">amount</Th>
                  <Th textAlign="center">status</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{data.wallet?.addr}</Td>
                  <Td textAlign="right">
                    {data.amount} {data.wallet?.network?.coin}
                  </Td>
                  <Td textAlign="center">
                    <OrderStatus isPaid={data.isPaid} paymentExpiration={data.paymentExpiration} />
                  </Td>
                </Tr>
                {data.awaitingTransactions.length === 1 && (
                  <Tr>
                    <Td>{data.awaitingTransactions[0].wallet?.addr}</Td>
                    <Td textAlign="right">
                      {data.awaitingTransactions[0].amount} {data.awaitingTransactions[0].wallet?.network?.coin}
                    </Td>
                    <Td textAlign="center">
                      <OrderStatus isPaid={data.awaitingTransactions[0].isPaid} paymentExpiration={data.paymentExpiration} />
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
